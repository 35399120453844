import React from 'react';
import ContentLoader from 'react-content-loader';
import classes from './Loaders.module.scss';

export const SkeletonPromotionPage = () => (
  <ContentLoader
    speed={2}
    height={1000}
    width='100%'
    className={classes.businessPage}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    {/* first line */}
    <rect x="0" y="0" rx="6" ry='6' width='40%' height='2.5%' />
    <rect x="0" y="3%" rx="6" ry='6' width='20%' height='1.5%' />
    <rect x="68%" y="0" rx="6" ry='6' width='5%' height='4%' />
    <rect x="75%" y="0" rx="6" ry='6' width='5%' height='4%' />
    <rect x="82%" y="0" rx="6" ry='6' width='5%' height='4%' />
    <rect x="88%" y="0" rx="6" ry='6' width='15%' height='4%' />
    <rect x="0" y="6%" rx="6" ry='6' width='50%' height='2.5%' />

    {/* img */}
    <rect x="0" y="9%" rx="6" ry='6' width='67%' height='28%' />

    {/* widget right top */}
    <rect x="72%" y="9%" rx="6" ry='6' width='10%' height='2%' />

    <rect x="72%" y="12%" rx="6" ry='6' width='3%' height='3%' />
    <rect x="77%" y="12%" rx="6" ry='6' width='5%' height='1.5%' />
    <rect x="77%" y="14%" rx="6" ry='6' width='4%' height='1%' />

    <rect x="72%" y="16%" rx="6" ry='6' width='3%' height='3%' />
    <rect x="77%" y="16%" rx="6" ry='6' width='5%' height='1.5%' />
    <rect x="77%" y="18%" rx="6" ry='6' width='4%' height='1%' />

    <rect x="72%" y="20%" rx="6" ry='6' width='3%' height='3%' />
    <rect x="77%" y="20%" rx="6" ry='6' width='5%' height='1.5%' />
    <rect x="77%" y="22%" rx="6" ry='6' width='4%' height='1%' />

    <rect x="72%" y="24%" rx="6" ry='6' width='3%' height='3%' />
    <rect x="77%" y="24%" rx="6" ry='6' width='5%' height='1.5%' />
    <rect x="77%" y="26%" rx="6" ry='6' width='4%' height='1%' />

    {/* widget right bottom */}
    <rect x="72%" y="30%" rx="6" ry='6' width='10%' height='2%' />

    <rect x="72%" y="33%" rx="6" ry='6' width='3%' height='4%' />
    <rect x="77%" y="33%" rx="6" ry='6' width='3%' height='4%' />
    <rect x="82%" y="33%" rx="6" ry='6' width='3%' height='4%' />
    <rect x="87%" y="33%" rx="6" ry='6' width='3%' height='4%' />
    <rect x="92%" y="33%" rx="6" ry='6' width='3%' height='4%' />
    <rect x="97%" y="33%" rx="6" ry='6' width='3%' height='4%' />

    <rect x="72%" y="38%" rx="6" ry='6' width='20%' height='2.5%' />

    {/* first widget */}
    <rect x="1.5%" y="40%" rx="6" ry='6' width='20%' height='2%' />
    <rect x="1.5%" y="43%" rx="6" ry='6' width='10%' height='1.5%' />
    <rect x="1.5%" y="45%" rx="6" ry='6' width='10%' height='1.5%' />
    <rect x="1.5%" y="48%" rx="6" ry='6' width='10%' height='1.5%' />
    <rect x="1.5%" y="50%" rx="6" ry='6' width='10%' height='1.5%' />
    <rect x="1.5%" y="54%" rx="6" ry='6' width='30%' height='1.5%' />


  </ContentLoader>
);