import React from 'react'
import QuestionsBlock from '../../components/aboutAppPage/QuestionsBlock'

export default function Questions() {

  return (
    <>
      <QuestionsBlock />
    </>
  )
}
